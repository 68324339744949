import React from 'react'
import '../assets/css/Product.styl'
function Product() {
        return (
            <div className="product">
                
                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_101.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo101.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        辰光记步
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        记录和统计您的运动情况，帮您在运动和健康的道路上走得更远、更健康。
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/cgjb_q/app.rpk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="cgjb_q/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
               </div>

                <div className="flex-justify-center box">
                <div className="flex-column content  flex-justify-center">
                    <img src={require("../assets/images/logo15.png")} alt="" width="118" height="118"/>
                    <div className="fs-40 mt-24">
                    智能WiFi秘书
                    </div>
                    <div className="fs-20 mt-24 bold-200 ">
                    智能WiFi秘书是您上网的好帮手，帮您解决网络安全问题、测试网速等都是一把好手。赶紧下载体验吧！
                    </div>
                    <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/znms/app.apk" target="_blank">
                        <div className="download-btn">
                            下载应用
                        </div>
                    </a>
                    <a href="znms/index.html" target="_blank">
                        <div className="detail-btn">
                            查看更多截图
                        </div>
                    </a>
                </div>
                <div>
                    <img src={require("../assets/images/phone_15.png")} alt="" width="324" height="514"/>
                </div>
                </div>

               

               


            </div>
        );
    }
export default Product;
